import React from 'react';

import Layout from '../../components/layout/Layout';
import Seo from '../../components/Seo';
import SplitSection from '../../components/SplitSection';
import Callout from '../../components/Callout';
import CalloutSimple from '../../components/CalloutSimple';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import container from '../../assets/drawings/container.png';
import alltests from '../../assets/drawings/alltests.png';
import branch from '../../assets/drawings/branch.png';
import complex from '../../assets/drawings/complex.png';


export default () => {
  const title = 'Generate On-Demand Environments For Testing';
  const description =
    'Strio gives you more control over your infrastructure spendings, allowing you to set a predefined budget, visualize your costs and switch off environments whenever you want.';
  const url = 'https://strio.app/usecases/cost';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <section id="hero-small" className="relative pa-0 bg-lownight overflow-hidden">
        <div className="background" />

        <div className="absolute w-full flex flex-row justify-center bottom-0">
          <AnchorLink
            href="#features"
            className="h-16 z-1 mb-4 lg:mb-6 rounded-full hover:bg-gray-300 transition duration-300 ease-in-out"
          >
          </AnchorLink>
        </div>
        <div className="flex items-center min-h-full relative">
          <div className="container pt-12 lg:pt-24 pb-16 mb-12 px-4">
            <div className="lg:px-8">
              <div className="text-center text-white md:text-center">
                <h1 className="mt-10 text-4xl bg:text-2xl lg:text-5xl xl:text-5xl font-bold leading-none">
                  On-Demand Environments, <br className="xl:inline hidden" /> For Better Testing
                </h1>
                <div className="flex justify-center">
                  <p className="font-light leading-snug text-base lg:text-xl xl:text-2xl mt-6 tracking-tight w-2/3">
                  With Strio, deploy fully provisionned environments in seconds to meet all your testing needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white py-12">
        <div className="flex justify-center">
          <div className="container md:w-2/3 text-center">
              <h3 className="text-2xl md:text-4xl font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
              Generate on-demand environments for testing
              </h3>
              <p className="text-base lg:text-xl mt-12 text-gray-700 px-8">
              Forget about the trouble managing staging or test environments: bottlenecks created by multiple 
              people working simultaneously on the same environment, conflicts, broken environments or unpredictable costs.
  
              <br/> <br/> Strio generates fully-provisioned and on-demand environments to help you forget about managing environments.
              </p>
          </div>
        </div>
      </section>  

      <section id="roi" className="bg-gray-300 shadow-inner py-12">
        <div class="flex flex-col md:flex-row px-8 md:px-24 py-12">
          <div class="flex-2 p-3">
          <h3 className="text-3xl md:text-4xl align-middle font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
            Test earlier, deliver faster.
          </h3>
          </div>
          <div class="flex-1 p-3 mx-12">
          <h3 className="text-3xl md:text-5xl font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
            70%
          </h3>
          <h3 className="text-xl md:text-2xl font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
            Cycle time reduction
          </h3>
          </div>
          <div class="flex-1 p-3 mx-12">
          <h3 className="text-3xl md:text-5xl font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
            50%
            </h3>
          <h3 className="text-xl md:text-2xl font-extrabold leading-snug px-8 mx-auto tracking-tight text-lownight font-display">
            Bugs in Production reduction
          </h3>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white shadow-inner">
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={branch}
              alt="Branch drawing"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Deploy an environment per branch
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Deploy one environment per pull or merge request. Each branch will have a dedicated environment to perform tests on.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={complex}
              alt="Complex test drawing"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Run complex test on dedicated environments
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              If you need to run complex and lengthy tests, Strio allows you to deploy quickly 
              an environment to run them and avoid blocking others.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={alltests}
              alt="List of tests performed"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Perform all your tests on Strio
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Whatever test you need to perform, Strio is the best support to it. 
              Run your manual tests, or use Strio to perform tests in your CI.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={container}
              alt="Container drawing"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Test everything, from containers to databases
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Strio deploys isolated environments from scratch, so you can deploy and test anything risk-free!
              </div>
            </div>
          }
        />
      </section>
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
